import { Button, Divider, Input, Select, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import "./dev.css";
import { FaServer } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";

const VirtualAgent = ({ onChange }) => {
  const [items, setItems] = useState([]);
  const [name, setName] = useState();
  const [selected, setSelected] = useState();
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    const options = JSON.parse(localStorage.getItem("va_options"));
    const server = localStorage.getItem("va_selected");
    if (options) {
      setItems(options);
    }
    if (server) {
      setSelected(server);
      onChange(server);
    }
  }, []);

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name]);
    localStorage.setItem("va_options", JSON.stringify([...items, name]));
    setSelected(name);
    setName("");
    onSelect(name);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onSChange = (value) => {
    const to_remove = [
      "X-Cache-Expiry",
      "X-Cache-Session",
      "X-DVC-Chat-Details",
      "X-DVC-RefreshToken",
      "X-DVC-Token",
      "X-DVC-Details",
      "X-DVC-TokenType",
      "X-DVC-Data",
    ];
    localStorage.setItem("va_selected", value);
    setName("");
    setSelected(value);

    if (onChange) {
      onChange(value);
      to_remove.forEach((k) => {
        localStorage.removeItem(k);
      });
      //
    }
  };

  const onSelect = (value) => {
    const prev_server = localStorage.getItem("va_selected");
    if (prev_server) {
      if (prev_server !== value) {
        onSChange(value);
      }
    } else {
      onSChange(value);
    }
  };

  return (
    <div className="flex">
      <Select
        id="sample-app-call-virtual-agent-dropdown"
        prefixCls="api"
        suffixIcon={<MdOutlineSupportAgent size={16} color="#EA2A8C" />}
        size="large"
        style={{
          width: 300,
        }}
        dropdownStyle={{ fontSize: 13 }}
        placeholder="Select Call Agent..."
        value={selected}
        onSelect={onSelect}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                id="sample-app-call-virtual-agent-input-box"
                placeholder="Virtual agent..."
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                id="sample-app-call-virtual-agent-add-button"
                size="large"
                type="text"
                style={{ fontSize: 13 }}
                icon={<PlusOutlined />}
                onClick={addItem}
              >
                Add Agent
              </Button>
            </Space>
          </>
        )}
        options={items.map((item) => ({
          label: item,
          value: item,
        }))}
      />
    </div>
  );
};

export default VirtualAgent;
