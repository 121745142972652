import React, { useCallback, useEffect, useRef, useState } from "react";

const Chat = ({
  isFocused,
  hidePower = false,
  onCallInit,
  virtualUserId,
  setShowChat,
  callInfo,
  onCollectedUserDetails,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  let retryCount = 0;
  const maxRetries = 2;

  window.destroyChat = () => {
    setIsVisible(false);
  };

  window.setChat = () => {
    setIsVisible(true);
  };

  window.onCloseClick = () => {
    if (window?.chatError === true) {
      window?.destroyChat();
    }
    setShowChat(false);
  };

  const getUserDetails = () => {
    const ud = localStorage.getItem("X-DVC-Data");
    if (ud) {
      try {
        const jsonUd = JSON.parse(ud);
        return jsonUd;
      } catch (err) {
        console.log(err);
      }
    }
    return null;
  };

  window.onPowerClick = (d) => {
    console.log("power pressed")
    window.chatError = false;
    window?.destroyChat(); // Hide the Chat component
    setShowChat(false);
    d?.proceed();
  };

  window.onCallConnected = (d) => {
    console.log("call connected", d?.data);
    const callType = d?.data?.callType ? d?.data?.callType : "AUDIO";
    const webClientUri = d?.data?.web_client_uri;
    onCallInit(callType, webClientUri);
    d?.proceed();
  };

  window.onChatError = (err) => {
    console.log(err);
    if (err?.error?.name === "CHAT_AUTHENTICATION_ERROR") {
      // window.chatError = true;
      const userDetails = getUserDetails();
      if (userDetails) {
        console.log("refresh token");

        err?.cancel();
        window?.xq?.getSdkAuth(
          userDetails,
          false,
          ({ responseData }) => {
            console.log("Running from app");
            err?.retry();
          },
          () => {
            console.log(`auth failed`);
            // localStorage.clear()
            setTimeout(() => {
              // localStorage.clear();
              window.chatError = true;
              window?.destroyChat();
              setShowChat(false);
              err?.proceed();
            }, 3000);
          },
          () => {}
        );
      } else {
        localStorage.clear();
        window.chatError = true;
        window?.destroyChat();
        setShowChat(false);
        err?.proceed();
      }
    } else if (err?.error?.name === "CHAT_ROOM_CONNECTION_ERROR" || err?.error?.name === "GROUP_CREATE_ERROR") {
      // window.chatError = true;
      console.log("chat room error");
      if (retryCount < maxRetries) {
        retryCount++;
        console.log(`Retry attempt ${retryCount}`);
        err?.cancel();
        localStorage.removeItem("X-Cache-Session");
        localStorage.removeItem("X-Cache-Expiry");
        err?.retry();
      }
      // window.onPowerClick(err);
    } else {
      err?.proceed();
      setTimeout(() => {
        window.chatError = true;
        localStorage.removeItem("X-Cache-Session");
        localStorage.removeItem("X-Cache-Expiry");
        window?.destroyChat();
        setShowChat(false);
      }, 2000);
    }
    err?.proceed();
  };

  window.onCollectedUserDetails = onCollectedUserDetails;

  return (
    <React.Fragment>
      {isVisible && (
        <div
          style={{
            width: 350,
            height: 500,
            borderRadius: 15,
            overflow: "hidden",
          }}
        >
          <chat-component
            group-type="chat-acd"
            is-focused={isFocused ? "true" : "false"}
            enable-marker="true"
            enable-mentions="false"
            enable-reply="false"
            chat-view-name="Customer Care"
            virtual-user={virtualUserId}
            show-call-options={callInfo ? "false" : "true"}
            on-power-click="onPowerClick"
            on-call-connected="onCallConnected"
            on-close-click="onCloseClick"
            on-error="onChatError"
            show-power-icon={`${!hidePower}`}
            on-collected-details="onCollectedUserDetails"
          ></chat-component>
        </div>
      )}
    </React.Fragment>
  );
};

export default Chat;
